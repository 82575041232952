body {overflow: hidden;}
.flex-container {
  display: flex;
  justify-content: space-between;
}
.card-body {
  padding: 60px 20px;
  max-width: 600px;
  margin: auto;
  min-height: calc(100vh - 135px);
  min-height: calc(100vhd - 135px);
}
form .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-muted {
  padding-top: 8px;
  padding-bottom: 16px;
}
.input-wrapper {margin-bottom: 10px;}
.main-logo {
  height: 34px;
  cursor: pointer;
}
header, footer {
  background: var(--bg);
  padding: 20px;
}
footer span {
  color: var(--primary);
  cursor: pointer;
}
.main-page {
  height: calc(100vh - 135px);
  height: calc(100vhd - 135px);
  overflow: auto;
  scroll-behavior: smooth;
}
.main-page section {padding: 60px 20px;}
.policy h3 {margin-top: 20px;}
.policy p {margin-top: 10px;}
header .account-details {min-width: 164px;}
header .btn {
  padding: 8px 16px;
  border-radius: 4px;
}
header .account-lang {
  margin-left: 12px;
  color: white;
  background: var(--text400);
}
.menu {
  display: flex;
  align-items: center;
}
header .menu {margin-top: 0;}
.menu-item {
  margin: 0 10px;
  cursor: pointer;
}
.submit-results {
  margin-bottom: 20px;
  color: var(--success);
  background: #D1FAE1;
  padding: 20px;
  border-radius: 12px;
}
.submit-results.error {
  color: var(--error);
  background: #FAD1D1;
}
form > .submit-results {margin-top: 10px;}
.sidebar {
  background: var(--bg);
  min-height: 100vh;
  max-height: 100vh;
  min-height: 100svh;
  max-height: 100svh;
  flex: 0 0 260px;
  overflow: overlay;
  width: 260px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo {line-height: 24px;}
.menu__icon .ci-primary {
  fill: var(--text300);
  stroke: unset;
}
.menu__item:where(.active, :hover, :focus) .menu__icon .ci-primary {
  fill: var(--primary);
}
.header {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0px;
  background: var(--primary);
  padding: 12px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 14px;
  z-index: 5;
}
.content-wrapper {
  flex: 1 1 100%;
  max-height: 100vh;
  overflow: overlay;
}
.content-body {padding: 40px 60px;}
.menu__item.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.featured.menu__text:after {
  content: attr(data-text);
  padding: 0 8px 1px;
  border-radius: 120px;
  margin-left: 8px;
  background: var(--error);
  color: #fff;
  min-width: 23px;
  text-align: center;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: -0.03em;
  display: inline-block;
}
.sidebar__bottom a {
  display: flex;
  align-items: center;
}
.sidebar__bottom .menu__icon .ci-primary {
  fill: var(--primary);
}
.account-details {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.account-lang {
  margin-left: 20px;
  border-radius: 4px;
  cursor: pointer;
  background: #3773ff;
  position: relative;
}
.account-lang-current {
  padding: 8px 16px;
  width: 50px;
  text-align: center;
}
.account-langs {
  box-shadow: 0px 4px 30px rgb(52 59 76 / 15%);
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  background: #fff;
  left: 0;
  padding: 8px 16px;
  color: var(--text500);
  display: grid;
  gap: 8px;
}
.offer p {margin-top: 10px;}
.offer p + h3 {margin-top: 20px;}
.align-center {text-align: center;}
.align-center form, .align-center h2, .history-info {
  max-width: 500px;
}
.history-info {margin-top: 30px; margin-bottom: 30px;}
table {
  border: 1px solid;
  border-color: #d8dbe0;
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;
}
table th {
  border: 1px solid;
  border-color: #d8dbe0;
  border-bottom: 2px solid;
  border-bottom-color: #d8dbe0;
  padding: 4px;
}
table tbody tr:nth-of-type(odd) {background-color: rgba(0,0,21,.05);}
table td {
  padding: 4px;
  border: 1px solid;
  border-color: #d8dbe0;
}
.badge {
  display: inline-block;
  padding: 4px 8px;
  text-align: center;
  white-space: nowrap;
  color: white;
  border-radius: 4px;
  line-height: 14px;
}
.badge-success {background: #14B856;}
.badge-error {background: #EB4747;}
.badge-warning {background: #FFBE33;}
.update-reports-table img {
  width: 20px;
  margin-left: 5px;
}
td a {color: var(--primary);}
.dashboard {position: initial;}
.dashboard__profile {display: block; order: 0 !important}
.dashboard .stats-block {min-height: 138px; order: 0 !important; cursor: pointer; transition: 0.2s;}
.dashboard .stats-block:hover {transform: translateY(-5px);}
.club_card_info__back {
  display: flex;
  align-items: center;
  background: none;
  border: none;
}
.club_card_info__back span {
  margin-left: 10px;
}
input.paypal-btn {
  width: 240px;
  padding: 12px 48px !important;
  background: rgb(253,195,57);
  border: none;
  border-radius: 100px;
  margin-top: 10px;
}
input.liqpay-btn {
  width: 240px;
  border: none;
  margin-top: 10px;
  padding: 0 !important;
}
.user .input, .user input {
  padding: 5px 0px!important;
  border: none;
  background: transparent;
}
.profile__row.user {align-items: center;}
.profile__row.user:nth-child(even) {background: #E8EEFC;}
.profile__row.user.body_s {padding-bottom: 5px; font-weight: 700; padding-top: 5px;}
.profile__row + .profile__row.user {margin-top: 0px;}
.profile__row.user > * + * {margin-left: 4px;}
.profile__row.user > div:nth-child(1) {text-indent: 5px;}
.profile__email {font-size: 15px; display: flex;}
footer a {
  color: var(--primary);
}
.input-wrapper#lang {
  margin-bottom: 0px;
  width: 166px;
}
.var {text-align: left;}
.input svg {
  position: absolute;
  top: 20px;
  right: 5px;
}
.card_buy__right {
  padding: 30px 20px;
  box-shadow: 0px 1px 5px rgba(40, 41, 48, 0.1);
  border-radius: 20px;
  max-width: 400px;
  text-align: center;
}
h2.price {
  padding-top: 15px;
  border-top: 1px solid rgba(58, 68, 82, 0.1);
  margin-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(58, 68, 82, 0.1);
}
input[type="range"] {
  padding: 0 !important;
}
.price-old {
  position: relative;
  font-weight: 500;
}
span.price-old:after {
  content: "";
  position: absolute;
  width: calc(100% + 8px);
  height: 3px;
  display: block;
  top: 50%;
  left: -4px;
  transform: translateY(-50%) rotate(-6deg);
  background: var(--error);
}
.price-old + .price-new {color: var(--error); margin-left: 8px;}
.wb-page {
  padding: 10px 16px;
  border-radius: 5px;
}
.wb-page.active {
  background: var(--primary);
  color: white;
}
.wb-page:not(.active) {cursor: pointer;}
.show_pass {
  cursor: pointer;
  font-size: 12px;
}
a.coinbase {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 60px;
  background: #0052ff;
  border-radius: 100px;
  margin-top: 10px;
}
a.coinbase img {
  max-height: 80%;
}
#check {
  background: var(--primary);
  color: white;
}
#check h2 {text-align: center;}
#check form {
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  margin-top: 20px;
}
#check .input-wrapper {
  flex: 1 1 100%;
  margin-bottom: 0;
}
#check input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#check button {
  flex: 0 0 100px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
#result > .container {
  display: grid;
  grid-template-columns: 40% 60%;
}
#result h2, #result p {
  text-align: center;
  margin-bottom: 20px;
}
.result__right form {
  box-shadow: 0px 1px 5px rgba(40, 41, 48, 0.1);
  margin: 0;
  width: 100%;
}
#result .result__right form > p {text-align: left;}
.price {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}
.report {
  margin-bottom: 20px;
}
.result__right button {width: 100%;}
#opt h2, #opt p {text-align: center; margin-bottom: 20px;}
#opt p {max-width: 600px; margin-left: auto; margin-right: auto;}
.opt__cards {
  display: flex;
  text-align: center;
}
.opt__cards h1, .opt__cards h3 {margin-bottom: 20px;}
.opt__cards h2 {color: var(--primary);}
.opt__cards .btn {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
}
.opt__cards .through {
  text-decoration: line-through;
  font-weight: 400;
  margin-bottom: 4px;
}
.opt__cards .new {
  color: #bb2c5e;
}
#faq, #tech {background: var(--bg);}
#faq h2 {text-align: center; margin-bottom: 40px;}
#faq p:not(:first-child), #example p:not(:first-child) {margin-top: 20px;}
#faq ul, #example ul {margin-top: 20px; padding-left: 30px; color: var(--text600);}
#example h2 {text-align: center; margin-bottom: 30px;}
#example p:first-child {margin-top: 10px;}
#example b {color: black;}
.example__grid {
  display: grid;
  grid-template-columns: auto 500px;
  gap: 40px;
}
.tech__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
#tech h2 {margin-bottom: 20px;}

@media (min-width: 1439px) {
  .dashboard .stats-block:nth-child(4) {margin-left: 0;}
}
@media (max-width: 1439px) {
  .content-wrapper {max-height: calc(100vh - 44px);}
  .dashboard .stats-block:nth-child(3) {margin-left: 0;}
}
@media (max-width: 1199px) {
  header > .flex-container {flex-wrap: wrap;}
  header .menu {
    order: 1;
    margin-top: 20px;
    justify-content: center;
    width: 100%;
  }
  footer > .flex-container {flex-direction: column;}
  footer > .flex-container > div + div {margin-top: 10px;}
  .main-page {
    height: calc(100vh - 206px);
    height: calc(100vhd - 206px);
  }
}
@media (max-width: 767px) {
  header .menu {
    flex-direction: column;
    align-items: start;
    margin-top: 0px;
    position: absolute;
    background: var(--bg);
    width: 100%;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px 20px;
    box-sizing: content-box;
    top: 44px;
    z-index: 1;
    display: none;
  }
  header .account-details {
    min-width: unset;
    justify-content: end;
    height: unset;
    width: unset;
  }
  .main-page {
    height: calc(100vh - 185px);
    height: calc(100vhd - 185px);
  }
  .card-body {
    min-height: calc(100vh - 185px);
    min-height: calc(100vhd - 185px);
    padding: 20px;
  }
  .card-body .col {margin-bottom: 10px;}
  footer {font-size: 14px;}
  .menu-item {margin: 10px 0px 0;}
  .sidebar {width: 100%;}
  .header {padding: 12px 16px 62px;}
  .content-body {padding: 20px 16px;}
  .account-name {
    position: absolute;
    top: 12px;
  }
  .account-details {
    justify-content: space-between;
    width: 100%;
    height: 42px;
  }
  .account-reports {align-self: flex-end;}
  .table-wrapper {
    overflow-x: auto;
    margin: 0 -16px;
    padding: 0 16px;
  }
  .table-wrapper table {white-space: nowrap;}
  .update-reports-table img {width: 17px;}
  .table-wrapper th:last-child {min-width: 80px;}
  .dashboard__profile {
    height: auto;
    margin-bottom: 20px;
  }
  .profile__row > * + * {margin-top: 0;}
  .grid {
    overflow-x: auto;
    margin: 0 -16px;
    padding: 0 16px;
  }
  .profile__row.user {
    display: grid;
    grid-template-columns: 230px 180px 60px 60px 90px;
    width: max-content;
    gap: 4px;
  }
  #tableOrders th:nth-child(2), #tableOrders th:nth-child(4), #tableOrders td:nth-child(2), #tableOrders td:nth-child(4) {
    display: none;
  }
  .sidebar__bottom {margin-bottom: 50px;}
  section h2 {font-size: 20px;}
  .main-page section {padding: 40px 20px;}
  .opt__cards {flex-direction: column;}
  #result > .container, .example__grid, .tech__grid {grid-template-columns: 1fr;}
  .result__right {margin-top: 20px;}
  #faq h2 {margin-bottom: 30px;}
  #example h2 {margin-bottom: 20px;}
  #check button {
    padding: 18px 20px;
    flex: 0 0 81px;
  }
}
.table-wrapper {
  height: 187px;
  overflow: auto;
  padding-bottom: 1px;
}
.table-wrapper table {
  margin-top: 0;
}
.banner {
  margin-top: 20px;
  border-radius: 10px;
  position: relative;
  min-height: 100px;
  text-align: left;
}
.banner__body {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(52,59,76,.15);
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.banner__title {margin-bottom: 10px;}
.settings__title {
  cursor: pointer;
  border-bottom: 1px solid var(--text100);
  padding-bottom: 12px;
}
.expander__icon {margin-left: 10px; transform: rotate(180deg); transition: transform .2s; pointer-events: none;}
.opened .expander__icon {transform: none;}
.settings {display: none;}
.settings__title.opened + .settings {display: block;}
.settings h2 {margin-bottom: 20px;}
.pr-add {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}
.pr-add button {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 1px solid var(--text100);
  border-radius: 6px;
  outline: none;
  padding: 8px 12px;
}
.pr-add button:hover {background: var(--bg);}
button.payment_delete {
  -webkit-appearance: none;
  appearance: none;
  background: no-repeat;
  border: none;
  color: var(--error);
  font-size: 24px;
  line-height: 24px;
  outline: none;
  position: absolute;
  right: 0;
  top: 14px;
  width: 24px;
}
.settings textarea {
  border: 1px solid var(--text100);
  border-radius: 10px;
  color: var(--text600);
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -.03em;
  line-height: 18px;
  outline: none;
  padding: 18px 14px 19px;
  width: 100%;
  height: -webkit-fill-available;
}
.content-body {
  height: 100vh;
  max-height: calc(100vh - 126px);
  overflow: auto;
}
.content-footer {
  display: flex;
  justify-content: end;
  background: var(--bg);
  padding: 10px;
}
.content-footer .menu__item {
  padding: 9px 20px;
}
.content-body > .policy > .container {
  max-width: unset;
}
.policy h2 + h3 {margin-top: 0px;}
.policy h2:not(:first-child) {margin-top: 30px;}
.policy h2 + h2:not(:first-child) {margin-top: 20px;}
.res-grid {
  display: grid;
  gap: 10px;
}
.header .menu__item {
  background: #14B856;
  margin-right: 20px;
  padding: 8px 12px;
  border-radius: 5px;
}
.header .menu__icon {stroke: white;}
.header .menu__icon .ci-primary {fill: white;}
.check-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 60px;
  align-items: center;
  margin: 0 auto;
  max-width: 900px;
  margin-top: 60px;
}
@media (max-width: 1439px) {
  .content-body {max-height: calc(100vh - 184px);}
}
@media (max-width: 767px) {
  .content-body {
    max-height: calc(100vh - 220px);
    max-height: calc(100dvh - 220px);
  }
  .content-footer .menu__item {padding: 8px 10px;}
  .header .menu__item {
    line-height: 16px;
    position: absolute;
    bottom: 12px;
    width: calc(100% - 32px);
    margin-right: 0;
    left: 16px;
    justify-content: center;
  }
  .check-grid {
    grid-template-columns: auto;
    gap: 20px;
    max-width: 200px;
    margin-top: 20px;
    text-align: center;
  }
}