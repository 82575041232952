body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
img {max-width: 100%;}
textarea {
  resize: vertical;
}
a {text-decoration: none; color: inherit;}
.hidden,
[hidden] {
  display: none !important;
}
.invisible {
  visibility: hidden;
}
input, button, .input {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
}
button {cursor: pointer;}
:root {
	--bg: #F6F8FA;
	--primary: #235EE7;
	--error: #E72323;
	--success: #0A5C2B;
	--text100: #E3E4E8;
	--text200: #C7C9D1;
	--text300: #ACADB9;
	--text400: #9092A2;
	--text500: #282930;
	--text600: #5D5F6F;
	--text700: #464753;
}
.text100 {color: var(--text100);}
.text200 {color: var(--text200);}
.text300 {color: var(--text300);}
.text400 {color: var(--text400);}
.text500 {color: var(--text500);}
.text600 {color: var(--text600);}
.text700 {color: var(--text700);}
.container {max-width: 1280px; margin: 0 auto; position: relative;}
.row {
  margin: 0 -15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.col {min-height: 1px; padding: 0 15px; box-sizing: border-box;}
body {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	letter-spacing: -0.02em;
	color: var(--text500);
}
h1 {
	font-weight: 600;
	font-size: 30px;
	line-height: 36px;
	letter-spacing: -0.03em;
}
h2 {
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: -0.03em;
}
h3 {
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: -0.03em;
}
.body_s, .btn, input, label, .input {
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.03em;
}
.body_xs, .body_xs_rg {
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.03em;
}
.body_xs_rg {
	font-weight: 400;
}
.main {
	display: flex;
}
.sidebar {
	background: var(--bg);
	min-height: 100vh;
	max-height: 100vh;
	flex: 0 0 320px;
	overflow: overlay;
	width: 320px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.mobile_menu {
	display: none;
}
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: transparent;
}
::-webkit-scrollbar-track {
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: var(--primary);
	border-radius: 340px;
}
.dialog-wrapper::-webkit-scrollbar-thumb {
	background-color: transparent;
}
.menu {
	margin-top: 30px;
}
.menu__block + .menu__block {
	padding-top: 10px;
	margin-top: 10px;
	border-top: 1px solid var(--text100);
}
.menu__item {
	display: flex;
	padding: 9px 10px;
	align-items: center;
	position: relative;
}
.menu__item.active {
	background: #FFFFFF;
	box-shadow: 0px 2px 2px rgba(40, 41, 48, 0.1);
	border-radius: 5px;
}
.menu__icon {
	margin-right: 10px;
	stroke: var(--text300);
	flex-shrink: 0;
}
.menu__item:where(.active, :hover, :focus) .menu__icon {
	stroke: var(--primary);
}
#msg-cnt {
	line-height: 23px;
	padding: 0 4px;
	border-radius: 120px;
	margin-left: 10px;
	background: var(--error);
	color: #fff;
	min-width: 23px;
	text-align: center;
}
.sidebar__bottom {
	padding: 30px 10px 10px;
}
.card {
	display: flex;
	flex-direction: column;
	position: relative;
}
.card_header {
	display: flex;
	cursor: pointer;
	align-items: center;
	width: 100%;
}
.card_photo {
	width: 48px;
	height: 48px;
	border-radius: 10px;
	background-size: cover;
	background-position: center;
	margin-right: 15px;
	flex: 0 0 48px;
}
.card_info {
	flex: 1 1 100%;
}
.card_info__title {
	margin-bottom: 3px;
}
.card__drop {
	flex: 0 0 16px;
}
.card_header.opened .card__drop {
	transform: rotate(180deg);
}
.card_body {
	display: none;
	padding: 20px;
    position: absolute;
    bottom: 60px;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
}
.card_body > * {
    padding: 10px;
}
.card_body a {
    display: block;
}
.card_body .btn {
    padding: 12px 10px;
    text-align: center;
    background: var(--error);
}
.card_body .btn.green {
    background: var(--success);
}
.card_header.opened + .card_body {
	display: block;
}
.content {
	flex: 1 1 100%;
	padding: 40px 70px 40px 62px;
	max-height: 100vh;
  overflow: overlay;
}
.card_notif {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: -40px -70px 40px -62px;
}
.card_notif .btn {
    padding: 9px 20px;
    margin-left: 10px;
    background: #CC8B00;
}
.card_notif.warning {
    background: #FFDF99;
    color: #996900;
}
.card_notif.error {
    background: #FCE8E8;
    color: #B81414;
}
.card_notif.error .btn {
    background: #E72323;
}
.content > h1, .content-body > h2, .align-center > h2 {
	margin-bottom: 30px;
}
.schedule {
	display: flex;
	margin: -10px;
	flex-wrap: wrap;
	position: relative;
}
.schedule__item {
	margin: 10px;
	width: 316px;
	padding: 20px;
	border-radius: 20px;
	background: var(--bg);
	position: relative;
}
.schedule__img {
	height: 155px;
	width: 100%;
	background-size: cover;
	background-position: center;
	border-radius: 20px;
}
.schedule__for {
	padding: 5px 10px;
	border-radius: 100px;
	background: #D1FAE1;
	color: #0A5C2B;
	position: absolute;
	top: 33px;
	left: 33px;
}
.schedule__for.partner {
	background: #FFDF99;
	color: #664600;
}
.schedule__for.leader {
	background: #E8EEFC;
	color: #0F348A;
}
.schedule__title {
	margin-top: 20px;
	margin-bottom: 5px;
}
.btn {
	display: inline-block;
	border: none;
	padding: 18px 30px;
	background: var(--primary);
	color: #fff;
	border-radius: 10px;
}
.schedule .btn {
	padding: 12px 20px;
	margin-top: 15px;
	border-radius: 10px;
	cursor: pointer;
}

/* LOGIN */
.login-logo {
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 120px;
	background: #FFFFFF;
	box-shadow: 0px 4px 200px rgba(0, 0, 0, 0.15);
	border-radius: 0px 0px 15px 15px;
}
.login {
	margin-top: 50px;
	background: #FFFFFF;
	box-shadow: 0px 1px 5px rgba(40, 41, 48, 0.1);
	border-radius: 20px;
	padding: 30px 20px;
	width: 356px;
	max-width: calc(100% - 20px);
	margin-left: auto;
	margin-right: auto;
}
.login-bg {
	background: #57AEFF;
	opacity: 0.2;
	filter: blur(200px);
	position: absolute;
	width: 1545px;
	height: 1431px;
	left: 50%;
	transform: translateX(-50%);
	bottom: -947px;
	z-index: -1;
}
.login-header {display: flex; justify-content: center;}
.login-header h3 {
	border-bottom: 2px solid var(--text100);
	width: 100%;
	text-align: center;
	padding-bottom: 10px;
}
.login-header h3.active {
	color: var(--primary);
	border-color: var(--primary);
}
.login-body {
	margin-top: 30px;
}
input, .input {
	border: 1px solid var(--text100);
	border-radius: 10px;
	padding: 18px 14px 19px;
	width: 100%;
	color: var(--text600);
	outline: none;
	transition: 0.2s;
}
input:focus {
	border-color: var(--primary);
}
input:not([type="submit"]):not(:placeholder-shown) {
	padding: 26px 14px 11px;
	position: relative;
}
input + span {
	pointer-events: none;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: -0.03em;
	color: var(--primary);
	position: absolute;
	top: 11px;
	left: 15px;
	opacity: 0;
	transition: 0.3s;
	transform: translateY(-8px);
}
input:not(:placeholder-shown) + span {
	opacity: 1;
	transform: translateY(0);
}
.login-body input:not(.ibtn) {margin-bottom: 20px;}
input::placeholder, textarea::placeholder {
	opacity: 1;
	color: var(--text400);
}
.login-body input.ibtn {
	background: var(--primary);
	color: #fff;
	cursor: pointer;
}
.input-wrapper {position: relative;}
.input-wrapper a {
	position: absolute;
	top: 21px;
	right: 15px;
	color: #759AF0;
}
.input-wrapper > svg {
	position: absolute;
	bottom: 13px;
	right: 15px;
	cursor: pointer;
	rotate: 45deg;
}
.input-wrapper svg.date-svg {
	pointer-events: none;
	top: 26px;
	right: 14px;
	fill: white;
}
.loginMessage .error {
	text-align: center;
	margin-bottom: 20px;
}
@media (max-width: 1439px) {
	.login-content {background-size: 820px;}
	.login-bg {
		width: 870.78px;
		height: 806.53px;
		bottom: -528px;
	}
}
@media (max-width: 767px) {
	.login-content {background-size: 470px;}
	.login-bg {
		width: 497.22px;
		height: 460.53px;
		bottom: -308px;
	}
}

/* STATS */
.stats {
	margin: -10px;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	max-width: 1008px;
}
.stats_range {
	position: absolute;
	right: 10px;
	top: -50px;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.stats_range span {
	margin-left: 10px;
	margin-right: 5px;
}
.stats_range__body {
	position: absolute;
	top: 32px;
	padding: 5px 0px;
	background: var(--bg);
	border-radius: 5px;
	box-shadow: 0px 1px 5px rgb(40 41 48 / 10%);
	width: 100%;
	display: none;
}
.stats_range.opened .stats_range__body {
	display: block;
}
.stats_range__body > * {
	padding: 8px 16px;
	display: block;
}
.stats_range__body > *:hover {
  color: var(--text400);
}
.stats-block {
	margin: 10px;
	width: 316px;
	padding: 20px;
	border-radius: 10px;
	background: var(--bg);
}
.stats__title {
	margin-bottom: 20px;
}
.stats__body {
	display: flex;
	justify-content: space-between;
}
.stats__left {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.stats__trend-wrapper {
	display: flex;
	align-items: center;
}
.stats__trend {
	padding: 3px 8px;
	margin-left: 10px;
	border-radius: 100px;
	display: flex;
}
.trend_up {
	color: var(--success);
	stroke: var(--success);
	background: #D1FAE1;
}
.trend_down {
	color: var(--error);
	stroke: var(--error);
	background: #FAD1D1;
}
.trend_down svg {
	transform: rotateX(180deg);
}
.stats__graf {
	width: 84px;
	height: 59px;
}

/* MESSAGES */
.dialog-box {
	display: flex;
	height: calc(100vh - 106px);
	margin-bottom: -40px;
	max-height: calc(100vh - 154px);
}
.dialogs {
	flex: 0 0 316px;
}
.dialog-wrapper {
	border-right: 1px solid var(--text100);
	margin-top: 10px;
	border-top: 1px solid var(--text100);
	height: calc(100% - 56px);
  overflow: overlay;
}
.dialog {
	padding: 10px;
	border-bottom: 1px solid var(--text100);
	display: flex;
	cursor: pointer;
}
.dialog.active {
	background: var(--primary);
}
.dialog__photo {
	width: 48px;
	height: 48px;
	border-radius: 10px;
	margin-right: 10px;
	background-size: cover;
	background-position: center;
	align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}
.dialog__photo:after {
    content: attr(data-name);
    font-size: 24px;
    color: #fff;
}
.dialog__body {
	width: 238px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.dialog__body_top {
	display: flex;
	margin-bottom: 5px;
	justify-content: space-between;
	align-items: center;
}
.dialog__body_title {
	display: flex;
}
.dialog.active .dialog__body_title {
	color: #FFFFFF;
}
.dialog.active .dialog__body_time {
	color: #FFFFFF;
	opacity: 0.3;
}
.dialog .dialog__body_mes {
	height: 18px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.dialog.active .dialog__body_mes {
	color: #FFFFFF;
	opacity: 0.5;
}
.messages {
	flex: 1 1 100%;
	max-width: 700px;
	margin-left: 22px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 20px;
}
.messages-box {
	margin-bottom: 21px;
	height: 100%;
	max-height: 100%;
	overflow: overlay;
}
.mes {
	display: flex;
}
.mes + .mes {
	margin-top: 15px;
}
.mes .dialog__photo {
	width: 32px;
	height: 32px;
	border-radius: 5px;
	flex-shrink: 0;
}
.mes .dialog__body {
	width: unset;
	max-width: calc(100% - 42px);
	word-wrap: break-word;
}
.mes .dialog__body_top {
	justify-content: start;
}
.mes .dialog__body_time {
	margin-left: 10px;
}
input.body_s {
	padding: 13px 14px !important;
}

/* WALLET */
.wallet {
	display: flex;
	padding: 20px;
	border: 1px solid var(--text100);
	border-radius: 10px;
	justify-content: space-between;
	max-width: 988px;
}
.wallet__info {
	display: flex;
	align-items: center;
}
.wallet__icon {
	padding: 12px;
	margin-right: 20px;
	border-radius: 10px;
	background: var(--primary);
}
.wallet__body h2 {
	margin-top: 5px;
}
.wallet__buttons {
	display: flex;
	align-items: center;
}
.wallet__buttons .grey, .btn.grey {
	background: #F1F1F3;
	color: #030917;
}
.wallet__buttons .grey {
    margin-right: 10px;
}
.history {
	margin-top: 40px;
	max-width: 988px;
}
.history__table {
	margin-top: 10px;
	display: table;
	table-layout: fixed;
	width: 100%;
}
.history__table__row {
	box-shadow: inset 0px -1px 0px var(--text100);
	display: table-row;
}
.history__table__col {
	padding: 16px;
	display: table-cell;
}
.history__table__col:last-child {
	padding: 11px 16px;
	width: 226px;
}
.history__table__col-inner {
	padding: 5px 10px;
	background: #D1FAE1;
	border-radius: 100px;
	color: #0A5C2B;
	display: inline-block;
}
.history__table__col-inner.red {
	background: #FAD1D1;
	color: #B81414;
}

/* PROFILE */
.profile {
	max-width: 652px;
}
.profile h3 {
	margin-bottom: 30px;
}
.profile__block {
	margin-bottom: 40px;
}
.profile__row {
	display: flex;
}
.profile__row + .profile__row {
	margin-top: 20px;
}
.profile__row > * + * {
	margin-left: 20px;
}
.profile__row > * {
	width: 100%;
	flex: 1 1 100%;
}
.row_x2 {
	flex-basis: 213.5%;
}
.row_x3 {
	flex-basis: 327%;
}
.profile__photo {
	width: 148px;
	height: 148px;
	flex: 0 0 148px;
	border: 1px solid var(--text100);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.no_photo {
	text-align: center;
}
.no_photo svg {
	margin-bottom: 9px;
}
.profile__col {
	display: flex;
	flex-direction: column;
}
.profile__col > *:not(:first-child) {
	margin-top: 20px;
}
label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
}
label:hover {
	color: var(--primary);
}
.profile__row label {
	width: auto;
	flex: 0 0 auto;
}
.radio, .checkbox {
	border: 1px solid var(--text300);
	border-radius: 105px;
	position: absolute;
	width: 18px;
	height: 18px;
	top: 0;
	left: 0;
}
.checkbox {
	border-radius: 5px;
}
label:hover .radio, label:hover .checkbox,
input[type="radio"]:checked + .radio {
	border-color: var(--primary);
}
input[type="checkbox"]:checked + .checkbox {
	border-color: var(--primary);
	background: var(--primary);
}
input[type="radio"]:checked + .radio::after {
	content: "";
	position: absolute;
	inset: 3px;
	background: var(--primary);
	border-radius: 50%;
}
input[type="checkbox"]:checked + .checkbox::after {
	content: "";
	position: absolute;
	inset: 0;
	background: url(/src/check.png) center no-repeat;
	width: 16px;
    height: 16px;
    background-size: 16px;
}
input[type="radio"], input[type="checkbox"] {
	width: 18px;
	height: 18px;
	position: absolute !important;
	opacity: 0;
	z-index: -1;
	left: 0;
	top: 0;
}
.input {
	position: relative;
	cursor: pointer;
}
.input svg {
	position: absolute;
	top: 18px;
	right: 15px;
}
.var {
	position: relative;
	top: 8px;
}
.variants {
	display: none;
	box-shadow: 0px 4px 30px rgb(52 59 76 / 15%);
	border-radius: 5px;
	position: absolute;
	top: calc(100% + 8px);
	left: -1px;
	width: 100%;
	z-index: 9999;
	background: #fff;
	max-height: 322px;
  overflow: overlay;
	padding: 10px 0;
}
.input.opened .variants {
	display: block;
}
.variant {
	padding: 10px 16px;
}
.checkbox-wrapper + .checkbox-wrapper {
	margin-top: 20px;
}
.align-right {
	width: 100%;
	text-align: right;
	display: flex;
  justify-content: right;
}

/* ARCHIVE */
.folders {
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
}
.folder {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: var(--bg);
	width: 232px;
	height: 138px;
	flex: 0 0 232px;
	border-radius: 10px;
	margin-right: 20px;
	margin-bottom: 20px;
}
.folder__icon {
	margin-bottom: 10px;
}
.archives {
	display: grid;
	grid-template-columns: repeat(4, minmax(232px, 1fr));
	gap: 20px;
	grid-gap: 20px;
	margin-top: 30px;
}
.archive {
	min-width: 232px;
}
.archive__img {
	border-radius: 10px;
}
.archive__title {
	margin-top: 10px;
	margin-bottom: 8px;
}
.faq-wrapper {
	border-top: 1px solid var(--text100);
}
.faq {
	border-bottom: 1px solid var(--text100);
	padding: 0 0 16px;
	position: relative;
}
.faq__title {
	padding-top: 25px;
	padding-bottom: 10px;
	padding-right: 54px;
	line-height: 24px;
	cursor: pointer;
}
.faq__body {display: none;}
.faq.opened .faq__body {display: block;}
.faq__body p + p {
	margin-top: 10px;	
}
.faq svg {
	position: absolute;
	top: 25px;
	right: 0;
	transition: 0.2s;
	pointer-events: none;
}
.faq.opened svg {
	transform: rotate(45deg);
}

/* CONSTRUCT */
.page-controls {
	display: flex;
	border-bottom: 2px solid var(--text100);
	position: relative;
}
.page-controls:after {
	content: "";
	width: 148px;
	height: 2px;
	position: absolute;
	background: var(--primary);
	bottom: -2px;
	left: 0;
	transition: 0.3s;
}
.data-page[data-page="1"] .page-controls:after {
	transform: translateX(0px);
}
.data-page[data-page="2"] .page-controls:after {
	transform: translateX(148px);
}
.data-page[data-page="3"] .page-controls:after {
	transform: translateX(296px);
}
.data-page[data-page="4"] .page-controls:after {
	transform: translateX(444px);
}
.data-page[data-page="5"] .page-controls:after {
	transform: translateX(592px);
}
.page-btn {
	flex: 0 0 148px;
	padding-bottom: 18px;
	text-align: center;
	text-transform: uppercase;
	color: var(--text600);
	font-size: 14px;
	line-height: 130%;
	cursor: pointer;
}
.data-page[data-page="1"] .page-btn:nth-child(1),
.data-page[data-page="2"] .page-btn:nth-child(2),
.data-page[data-page="3"] .page-btn:nth-child(3),
.data-page[data-page="4"] .page-btn:nth-child(4),
.data-page[data-page="5"] .page-btn:nth-child(5) {
	color: var(--primary);
}
.page-wrapper {
	padding-top: 30px;
}
.page {
	display: none;
}
.data-page[data-page="1"] .page:nth-child(1),
.data-page[data-page="2"] .page:nth-child(2),
.data-page[data-page="3"] .page:nth-child(3),
.data-page[data-page="4"] .page:nth-child(4),
.data-page[data-page="5"] .page:nth-child(5) {
	display: flex;
}
.page__col {
	flex: 1 1 50%;
}
.page__col + .page__col {
	margin-left: 20px;
}
.site-input {
	display: flex;
}
.site-input .input-wrapper:nth-child(1) {
	flex: 0 0 168px;
}
.site-input .input-wrapper:nth-child(2) {
	flex: 1 1 100%;
}
.site-input .input-wrapper:nth-child(1) input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.site-input .input-wrapper:nth-child(2) input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-left: none;
	background: transparent;
}
.site-input-desc {
	padding-left: 167px;
	display: flex;
}
.site-input-desc button, .site-input-desc a {
	border: 1px solid var(--text100);
	background: transparent;
	border-top: none;
	border-radius: 0px 0px 0px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 18px 0;
	flex: 1 1 50%;
}
.site-input-desc button:nth-child(2) {
	border-radius: 0px 0px 10px 0px;
	border-left: none;
}
.site-input-desc svg {
	margin-right: 10px;
}
.site-info {
	flex: 0 0 50%;
	margin-top: 30px;
}
.page__media-container {
	border: 1px solid var(--text100);
	border-radius: 10px;
	width: 484px;
	height: 272px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.page__media-container img, .page__media-container video {
	display: none;
}
.no_media {
	text-align: center;
}
.no_media__icon {
	margin-bottom: 9px;
}
.page__media-container + .page__media-container {
	margin-top: 20px;
}
.page_btn-wrapper {
	text-align: right;
	margin-top: 40px;
}

/* RATING */
.rating-wrapper {
	margin: -10px;
	display: flex;
	flex-wrap: wrap;
}
.rating {
	margin: 10px;
	width: 148px;
}
.rating__photo {
	width: 148px;
	height: 148px;
	background-position: center;
	background-size: cover;
	border-radius: 20px;
	outline: 4px solid transparent;
	outline-offset: -4px;
	position: relative;
}
.rating:nth-child(1) .rating__photo {outline-color: #FFAF01;}
.rating:nth-child(2) .rating__photo {outline-color: #C7C9D1;}
.rating:nth-child(3) .rating__photo {outline-color: #CD7F32;}
.rating:nth-child(1) .rating__photo:after {
	content: "1";
	background: #FFAF01;
}
.rating:nth-child(2) .rating__photo:after {
	content: "2";
	background: #C7C9D1;
}
.rating:nth-child(3) .rating__photo:after {
	content: "3";
	background: #CD7F32;
}
.rating:nth-child(1) .rating__photo:after,
.rating:nth-child(2) .rating__photo:after,
.rating:nth-child(3) .rating__photo:after {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	letter-spacing: -0.02em;
	border-radius: 50%;
}
.rating h3 {
	margin-top: 15px;
	margin-bottom: 5px;
}
.create_tr {
	position: absolute;
	z-index: 9;
	top: -59px;
	left: 373px;
	margin-top: 0 !important;
}
.teachers {
	margin: -10px;
	display: flex;
	flex-wrap: wrap;
}
.teacher {
	margin: 10px;
	background: var(--bg);
	border-radius: 20px;
	flex: 0 0 484px;
	padding: 20px;
}
.teacher__top {
	display: flex;
	margin-bottom: 15px;
}
.teacher__photo {
	width: 190px;
	height: 190px;
	background-position: center;
	background-size: cover;
	border-radius: 20px;
	margin-right: 20px;
}
.teacher .btn {
	padding: 12px 20px;
	width: 190px;
	text-align: center;
}
.teacher__body h3 {
	margin-top: 5px;
	margin-bottom: 15px;
}
.teacher__text {
	display: flex;
	align-items: center;
}
.teacher__text svg {
	margin-right: 10px;
}
.teacher__text + .teacher__text {
	margin-top: 8px;
}
.teacher__icons {
	display: flex;
	margin-top: 15px;
}
.teacher__icon {
	width: 32px;
	height: 32px;
	margin-right: 10px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.teacher__icon:nth-child(1) {background: #0088CC; padding-right: 2px;}
.teacher__icon:nth-child(2) {background: #25D366;}
.teacher__icon:nth-child(3) {background: #0077FF;}
.teacher__icon:nth-child(4) {background: #F00073;}
.panel-title {
	position: relative;
}
.panel-stats {
	display: flex;
	position: absolute;
	top: 11px;
	right: 0;
}
.panel-stats div {margin-left: 30px;}
.folder:hover .folder__icon {
	stroke: var(--primary);
}
.dashboard {
	max-width: 988px;
	display: flow-root;
}
.dashboard > * {
	float: left;
	margin-bottom: 20px;
}
.dashboard h3 {margin-bottom: 20px;}
.dashboard__profile {
	width: 316px;
	height: 138px;
	display: flex;
}
.dashboard__photo {
	margin-right: 20px;
	border-radius: 20px;
	width: 138px;
	height: 138px;
	background-position: center;
	background-size: cover;
	flex-shrink: 0;
}
.dashboard__profile h3 {margin-bottom: 5px;}
.dashboard__rang, .dashboard__card {
	padding: 5px 10px;
	border-radius: 100px;
	display: inline-block;
}
.dashboard__rang {
	background: var(--text100);
	margin-top: 10px;
}
.dashboard__card {
	background: #FFDF99;
	margin-top: 10px;
	color: #664600;
}
.dashboard .stats-block {
	margin: 0;
	margin-bottom: 20px;
	margin-left: 20px;
}
.dashboard__rating {
	width: 316px;
	height: 296px;
	border-radius: 10px;
	background: var(--bg);
	padding: 20px;
}
.rating-block {
	display: flex;
}
.rating-block + .rating-block {
	padding-top: 20px;
	margin-top: 19px;
	border-top: 2px solid var(--text100);	
}
.rating__img {
	width: 42px;
	height: 42px;
	flex-shrink: 0;
	margin-right: 10px;
	border-radius: 5px;
	background-position: center;
	background-size: cover;
}
.rating__body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.dashboard__news {
	width: 316px;
	height: 367px;
	background: var(--bg);
	border-radius: 10px;
	padding: 20px;
}
.dashboard__news .news-body + .news-body {
	padding-top: 20px;
	margin-top: 18px;
	border-top: 2px solid var(--text100);	
}
.news-block h3 {margin-bottom: 15px;}
.news-body {
	display: flex;
}
.news__img {
	width: 64px;
	height: 64px;
	flex-shrink: 0;
	margin-right: 10px;
	border-radius: 10px;
	background-position: center;
	background-size: cover;
	position: relative;
}
.play-button {
	position: absolute;
	background: var(--primary);
	padding-left: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.news__right {
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.news__right .body_xs {
	margin-bottom: 5px;
}
.dashboard__trans {
	background-color: var(--bg);
	width: 652px;
	height: 367px;
	border-radius: 10px;
	margin-left: 20px;
	background-position: center;
	background-size: cover;
	position: relative;
	cursor: pointer;
}
.dashboard__trans .play-button {
	width: 64px;
	height: 64px;
}
.dashboard__trans svg {
	transform: scale(1.5);
}
.profile .btn.grey {margin-right: 20px;}
.mastery .faq-wrapper {margin-top: 84px;}
.mastery {
	display: flex;
}
.mastery__left {
	flex: 0 1 673px;
	border-right: 1px solid var(--text100);
	padding-right: 20px;
}
.mastery__right {
	flex: 0 0 316px;
	padding-left: 20px;
}
.chat__pages .page {
	flex-direction: column;
}
.online-wrapper {
	border-radius: 0px 0px 10px 10px;
	height: 34px;
	display: flex;
	background: var(--bg);
	align-items: center;
	padding-left: 15px;
	padding-right: 13px;
	margin-bottom: 22px;
	justify-content: space-between;
}
.online__text {
	padding-left: 13px;
	position: relative;
	color: #14B856;
}
.online__text:before {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	background: #14B856;
}
.online__flex {
    display: flex;
    align-items: center;
}
.online__all, .online__partners {
	display: flex;
	align-items: center;
}
.online__partners {
	margin-left: 10px;
}
.online__all span, .online__partners span {
	padding: 3px;
	color: white;
	text-align: center;
	min-width: 21px;
	border-radius: 20px;
	margin-left: 3px;
}
.online__all span {
	background: #14B856;
}
.online__partners span {
	background: var(--primary);
}
.mastery__right .btn {
	margin-top: 44px;
}
.chat__pages .dialog__body_mes {
	padding-right: 8px;
}

/* WORKBOOK */
.h1-wrapper {
	display: flex;
	max-width: 988px;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}
.workbook-wrapper {
	display: flex;
	max-width: 988px;
	margin-top: 30px;
}
.filters {
	display: flex;
}
.filters .input-wrapper {
	width: 232px;
}
.filters .input-wrapper + .input-wrapper {
	margin-left: 20px;
}
.filters input, .filters .input {
	padding: 13px 14px !important;
}
.filters .var {
	top: 0;
}
.filters .input svg {
	top: 12px;
}
.workbook__left {
	flex: 0 0 232px;
	margin-right: 20px;
}
.workbook {
	display: flex;
	flex: 1 1 100%;
	flex-wrap: wrap;
	margin: -10px;
}
.workbook__buttons {
	display: flex;
}
.workbook__buttons .btn {
	padding: 12px 22px;
}
.workbook__buttons .grey {
	margin-right: 0;
	margin-left: 10px;
}
.workbook__folders {
	margin-top: 30px;
}
.workbook__folder {
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.workbook__folder__action {
	height: 20px;
}
.workbook__folder__title {
	display: flex;
	align-items: center;
}
.workbook__folder__title span:nth-child(2) {
	width: 155px;
	margin-left: 5px;
	margin-right: 5px;
}
.workbook__user {
	border-radius: 10px;
	width: 232px;
	background: var(--bg);
	padding: 20px;
	margin: 10px;
}
.workbook__user__top {
	display: flex;
	position: relative;
}
.user__photo {
	position: relative;
	width: 36px;
	height: 36px;
	border-radius: 5px;
	margin-right: 10px;
	flex-shrink: 0;
	background-size: cover;
	background-position: center;
}
.user__photo.online:after {
	content: "";
	background: #14B856;
	width: 12px;
	height: 12px;
	border: 2px solid #FFFFFF;
	position: absolute;
	right: -1px;
	bottom: -1px;
	border-radius: 50%;
}
.user__title {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-right: 20px;
}
.user__title .next_btn {margin-left: 10px; color: #CC8B00; cursor: pointer;}
.workbook__user__top > svg {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
}
.workbook__user__body {
	margin-top: 10px;
}
.workbook__user__body .teacher__text {
	margin-top: 5px;
	align-items: flex-start;
}
.workbook__user__info {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.workbook__indicators {
	display: flex;
}
.workbook__indicators svg + svg {margin-left: 4px;}
.workbook__social {
	display: flex;
}
.workbook__social__link {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.workbook__social__link + .workbook__social__link {
	margin-left: 7px;
}
.social_mes {
	background: var(--primary);
}
.social_inst {
	background: #F00073;
}
.social_wa {
	background: #25D366;
}
.social_vk {
	background: #0077FF;
}
.social_tg {
	background: #0088CC;
	padding-right: 2px;
}
.workbook__full {
	display: none;
	position: fixed;
	box-shadow: 0 0 0 10000px rgb(5 17 46 / 20%);
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	animation: slideIn 0.3s;
	will-change: transform;
	z-index: 5;
}
@keyframes slideIn {
	from {
		opacity: 0;
		transform: translate(-50%, calc(-50% - 50px));
	}
	to {
		opacity: 1;
		transform: translate(-50%, -50%);
	}
}
.workbook__full.opened {
	display: block;
	background: #FFFFFF;
	border-radius: 20px;
	padding: 20px;
}
.workbook__full-wrapper {
	display: flex;
	margin-top: 30px;
}
.workbook__full-user {
	width: 374px;
}
.workbook__full-actions {
	display: flex;
	flex-direction: column;
	width: 168px;
	margin-left: 70px;
	position: relative;
}
.workbook__full-actions .btn {
	margin: 0;
	margin-top: 10px;
	padding: 12px 15px;
}
.workbook__full-actions .btn.delete {
	background: #FAD1D1;
	color: var(--error);
}
.workbook__full-user .user__photo {
	border-radius: 50%;
}
.workbook__full .workbook__indicators svg + svg {
	margin-left: 10px;
}
.workbook__full .workbook__user__body .teacher__text {
	align-items: center;
}
.teacher__text .copy_btn {
	margin-left: 10px;
	color: var(--primary);
	cursor: pointer;
}
.workbook__full .workbook__user__info {
	margin-bottom: 30px;
}
.workbook__full-note {
	border-radius: 10px 10px 10px 0px;
	padding: 10px;
	background: var(--bg);
	margin-top: 5px;
}
.workbook__full-note:nth-child(2) {margin-top: 10px;}
.close_block {
	position: absolute;
	cursor: pointer;
	top: 20px;
	right: 20px;
}
.workbook__full-actions__move, .workbook__full-actions__note, .workbook__full-actions__alert {
	position: absolute;
	left: 0;
	display: none;
	background: white;
	padding: 20px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	width: 278px;
}
.workbook__full-actions__move {top: 123px;}
.workbook__full-actions__alert {top: 175px;}
.workbook__full-actions__note {top: 227px;}
.workbook__full-actions__move.opened,
.workbook__full-actions__note.opened,
.workbook__full-actions__alert.opened {
	display: block;
}
.workbook__full-actions__move button,
.workbook__full-actions__note button,
.workbook__full-actions__alert button {
	width: 100%;
	margin-top: 10px;
}
.workbook__full-actions__move .body_s,
.workbook__full-actions__note .body_s,
.workbook__full-actions__alert .body_s {
	width: 100%;
	margin-bottom: 13px;
}

/* TRANSLATION */
.translation {
	display: flex;
}
.translation__left {
	flex: 1 1 100%;
	margin-right: 40px;
}
.translation__right {
	flex: 0 0 296px;
}
.translation__right > .btn {
	width: 100%;
	margin-bottom: 30px;
	padding: 12px 20px;
}
.h1-time-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}
.h1-time {
	display: flex;
	align-items: center;
	margin-right: 336px;
}
.h1-time svg {
	margin-right: 5px;
}

/* TEAM */
.team_filters {
	display: flex;
	margin-bottom: 20px;
}
.team_filters > * {
	flex: 1 1 100%;
}
.team_filters > *:first-child {
	flex: 0 0 232px;
}
.team_filters > * + * {
	margin-left: 10px;
}
.team_filters .var {top: 0;}
.team_filters input, .team_filters .input {
	padding: 13px 14px !important;
}
.team_filters .input svg {
	top: 13px;
}
.team-wrapper {
	display: flex;
	margin: -10px;
	flex-wrap: wrap;
}
.team-wrapper .user__title {
	padding-right: 0;
}
.with-icons, .team_icons {
	display: flex;
}
.team_icons svg {
	margin-left: 5px;
}
.team-wrapper .workbook__social__link:not(:last-child):not(.active) {
	background: var(--text100);
}
.team-wrapper .workbook__social__link:not(:last-child):not(.active) svg {
	fill: var(--text400);
}
.team-wrapper .teacher__text {
	align-items: center;
}
.modal__text {
    margin-top: 30px;
    font-weight: 400;
    padding-bottom: 10px;
    overflow: auto;
}
.messages-back {
	display: none;
}
.ref-wrapper {flex: 0 0 400px;}
.sidebar {
	flex: 0 0 240px;
	width: 240px;
}

@media (max-width: 1439px) {
	.main {flex-direction: column;}
	.sidebar {
    position: fixed;
    z-index: 9;
		transform: translateX(-105%);
		transition: transform 0.3s;
	}
	body.sidebar_visible {overflow: hidden;}
	body.sidebar_visible .sidebar {
		transform: translateX(0);
		box-shadow: 0 0 0 1500px #05112e33;
	}
	body.sidebar_visible .content {pointer-events: none;}
	.mobile_menu {display: flex; padding: 20px; justify-content: space-between; align-items: center;}
	.content {padding: 20px; max-height: unset;}
	.card_notif {margin: -20px -20px 20px;}
	.stats {max-width: unset; margin: -5px;}
	.stats_range {top: -45px;}
	.stats-block {margin: 5px; width: 354px;}
	.content > h1, .content-body > h2, .align-center > h2 {margin-bottom: 20px;}
	.mobile_menu__toggle {
		width: 24px;
		height: 24px;
		position: relative;
		cursor: pointer;
		z-index: 19;
	}
	.mobile_menu__toggle > * {
		width: 20px;
		height: 2px;
		background: black;
		border-radius: 1px;
		position: absolute;
		left: 2px;
		transition: 0.3s;
	}
	.toggle__line_1 {top: 5px; transition: 0.05s; transition-delay: 0.2s;}
	.toggle__line_2, .toggle__line_3 {top: 11px;}
	.toggle__line_4 {bottom: 5px; transition: 0.05s; transition-delay: 0.2s;}
	body.sidebar_visible .toggle__line_1 {opacity: 0; transform: translateY(-7px); transition-delay: 0s;}
	body.sidebar_visible .toggle__line_4 {opacity: 0; transform: translateY(7px); transition-delay: 0s;}
	body.sidebar_visible .toggle__line_2 {transform: rotate(-45deg);}
	body.sidebar_visible .toggle__line_3 {transform: rotate(45deg);}
	.dialog-box {
		margin-bottom: -20px;
		max-height: calc(100vh - 218px);
	}
	.folders {margin-right: -20px;}
	.folder {flex: 0 0 229px;}
	.archives {grid-template-columns: repeat(3, minmax(232px, 1fr));}
	.page {flex-direction: column;}
	.page__col {position: relative;}
	.site-input {padding-right: 125px;}
	.site-input .input-wrapper:nth-child(1) {flex-basis: 229px;}
	.site-input-desc {padding-left: 228px; padding-right: 125px;}
	.site-input-desc:nth-child(3) {
    padding: 0;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 18px;
	}
	.site-info {margin-top: 0;}
	.site-info + .site-info {margin-top: 15px;}
	.page__col + .page__col {margin-left: 0px; margin-top: 30px;}
	.page__media-container {
    display: inline-flex;
    width: calc(50% - 10px);
	}
	.page__media-container + .page__media-container {
    margin-top: 0px;
    margin-left: 16px;
	}
	.page_btn-wrapper {margin-top: 30px;}
	.rating {width: 129px;}
	.rating__photo {width: 129px; height: 129px;}
	.rating .body_s {font-size: 12px; line-height: 15px;}
	.schedule__item {width: calc(50% - 20px);}
	.create_tr {top: -49px; left: unset; right: 10px;}
	.teacher {flex-basis: calc(50% - 20px); width: calc(50% - 20px);}
	.teacher__top {flex-direction: column;}
	.teacher__photo {width: 72px; height: 72px;}
	.teacher__top {
    flex-direction: column;
    position: relative;
	}
	.teacher__body .body_xs {
    position: absolute;
    top: 16px;
    left: 87px;
	}
	.teacher__body h3 {
    position: absolute;
    top: 31px;
    left: 87px;
	}
	.teacher__body {margin-top: 20px;}
	.teacher .btn {width: 200px;}
	.profile__row:nth-child(8) .align-right {
		flex-direction: column;
	}
	.profile__row:nth-child(8) button:nth-child(1) {
		margin-right: 0;
		margin-bottom: 10px;
	}
	.mastery__right .btn {width: 100%;}
	.workbook__left {
    margin-right: 0;
    display: flex;
		flex-basis: auto;
	}
	.workbook__folders {margin-top: 0px; width: 229px;}
	.workbook__buttons {
    flex-direction: column;
    width: 229px;
    margin-right: 20px;
	}
	.workbook__buttons .btn {padding: 14px 23px;}
	.workbook__buttons .grey {
    margin-left: 0;
    margin-top: 10px;
	}
	.filters {
    flex-direction: column;
    position: absolute;
    right: 20px;
    top: 174px;
	}
	.filters .input-wrapper {width: 229px;}
	.filters .input-wrapper + .input-wrapper {margin-left: 0; margin-top: 10px;}
	.workbook {margin: 20px -10px -10px;}
	.workbook__user {width: 229px;}
	.workbook-wrapper {flex-direction: column;}
	.workbook__full-actions__move, .workbook__full-actions__note, .workbook__full-actions__alert {
    left: unset;
    right: -10px;
	}
	.translation {flex-direction: column;}
	.translation__left {
    margin-bottom: 30px;
    margin-right: 0;
	}
	.translation__left img {width: 100%;}
	.translation__right > .btn {
    width: 218px;
    position: absolute;
    top: 105px;
    right: 20px;
	}
	.translation .page-btn {flex-basis: 50%;}
	.translation .page-controls:after {width: 50%;}
	.translation .data-page[data-page="2"] .page-controls:after {transform: translateX(100%);}
	.translation .online__text {margin-right: calc(100% - 213px);}
	.h1-time {margin-right: 236px;}
	.dashboard__photo {
    width: 127px;
    height: 127px;
	}
	.dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
		position: relative;
	}
	.dashboard > * {width: calc(50% - 10px);}
	.dashboard__profile {order: 1;}
	.dashboard__rating {order: 2; margin-left: 20px;}
	.dashboard .stats-block:nth-child(2) {order: 4; margin-left: 0;}
	.dashboard .stats-block:nth-child(3) {order: 5;}
	.dashboard .stats-block:nth-child(5) {order: 6; margin-left: 0;}
	.dashboard .stats-block:nth-child(6) {order: 7;}
	.dashboard .news-block {order: 8; margin-left: 0;}
	.dashboard__news {order: 9; margin-left: 20px; height: auto;}
	.dashboard__news .news-body + .news-body {display: none;}
	.dashboard__trans {order: 10; width: 100%; margin-left: 0;}
	.dashboard .stats-block:nth-child(7) {
    margin-left: 0;
    position: absolute;
    top: 158px;
	}
	.team_filters {flex-wrap: wrap;}
	.team_filters > *:nth-child(1), .team_filters > *:nth-child(2), .team_filters > *:nth-child(3) {
    flex-basis: calc(33% - 10px);
	}
	.team_filters > *:nth-child(4) {display: none;}
	.team_filters > *:nth-child(5) {margin-left: 0; margin-top: 10px;}
	.team-wrapper {margin: -15px -5px -5px;}
	.workbook__user {
    width: 236px;
    margin: 5px;
	}
	.history__table__col:first-child {width: 100px;}
	.club_card_info__left {padding-top: 40px; padding-left: 20px;}
	.club_card_info {position: relative;}
	.club_card_info__right {position: absolute; height: 100%; right: 0px;}
	.club_card_info__card {right: 84px;}
	.card_buy {flex-direction: column;}
	.card_buy__left {width: 100%;}
	.card_buy__right {margin-top: 40px; width: 100%;}
	.card_buy__button .body_xs {width: unset;}
	.modal__text {width: calc(100vw - 160px); max-height: calc(100vh - 225px);}
	.lk .tour, .lk .tour__body {flex-direction: row;}
	.lk .tour__image {flex: 0 0 232px;}
	.lk .tour__image img {height: 194px;}
	.lk .tour__body__left {
        flex: 0 1 100%;
        width: 100%;
        padding-right: 15px;
        margin-right: 0px;
    }
    .lk .tour__info {
        height: unset;
        margin: 0;
        border-left: 1px solid rgba(138, 147, 168, 0.1);
        flex: 0 0 166px;
    }
    .lk .info__block {
        margin: 5px 0;
        padding-left: 34px;
    }
}
@media (max-width: 767px) {
	.mobile_menu {padding: 10px 15px;}
	.content {padding: 20px 10px;}
	.card_notif {margin: -20px -10px 20px;}
	.stats-block {width: 100%;}
	.stats_range {
    position: initial;
    margin-left: 5px;
    margin-bottom: 15px;
	}
	.sidebar {width: 100%;}
	.dialog_page .content {overflow: hidden;}
	.dialogs {flex-basis: 100%;}
	.messages {flex-shrink: 0; margin-left: 20px; max-width: calc(100vw - 30px);}
	.dialog-box {transition: 0.3s; position: relative; max-height: calc(100vh - 190px);}
	.dialog-box.opened {transform: translateX(calc(-100% - 20px))}
	.dialog-wrapper {border-right: none;}
	.command-block {flex: 0 0 100%;}
	.messages-back {
		display: flex;
		position: absolute;
    top: -56px;
    width: 100%;
    background: white;
    padding: 9px 0;
	}
	.messages-box {margin-bottom: 10px;}
	.messages-box::-webkit-scrollbar-thumb {background-color: transparent;}
	.messages-back svg {margin-right: 10px;}
	.wallet {flex-direction: column;}
	.wallet__buttons {margin-top: 20px;}
	.wallet__buttons .btn {
    width: 50%;
    text-align: center;
	}
	.history {margin-top: 30px; overflow-x: auto;}
	.history__table {table-layout: auto;}
	.history__table__col {white-space: nowrap;}
	.profile h3 {margin-bottom: 20px;}
	.profile__block {margin-bottom: 30px;}
	.profile__row {flex-direction: column;}
	.profile__row > * + * {margin-left: 0px; margin-top: 20px;}
	.profile__col > *:not(:first-child) {flex-direction: row;}
	label[for="g2"] {margin-left: 28px; margin-top: 0;}
	.mobile__col {flex-direction: row;}
	.mobile__col > * + * {margin-left: 20px; margin-top: 0px;}
	.ref-wrapper {flex-basis: auto;}
	.profile button {width: 100%;}
	.folders {margin: 20px -5px -5px -5px;}
	.folder {flex-basis: calc(50% - 10px); margin: 5px;}
	.archives {grid-template-columns: minmax(232px, 1fr);}
	.site-input {padding-right: 0px;}
	.site-input .input-wrapper:nth-child(1) {flex-basis: 140px;}
	.site-input-desc {padding-left: 139px; padding-right: 0px;}
	.site-input-desc:nth-child(3) {
    padding-left: 139px;
    position: initial;
    flex-direction: row;
	}
	button#copy1 {flex: 0 0 58px;}
	button#copy1 span {display: none;}
	button#copy1 svg {margin: 0;}
	.site-info {margin-top: 10px;}
	.site-info + .site-info {margin-top: 10px;}
	.construct .page-btn {flex-basis: 33%;}
	.construct .page-controls:after {width: 33%;}
	.construct .data-page[data-page="2"] .page-controls:after {transform: translateX(100%);}
	.construct .data-page[data-page="3"] .page-controls:after {transform: translateX(200%);}
	.page__col:nth-child(2) {display: flex; flex-wrap: wrap;}
	.page__media-container {width: calc(50% - 5px); height: 50vw;}
	@supports (aspect-ratio: 1 / 1) {
		.page__media-container {aspect-ratio: 1 / 1; height: auto;}
	}
	.page__media-container + .page__media-container {margin-left: 10px;}
	.page_btn-wrapper {width: 100%;}
	.page_btn-wrapper button {width: 100%;}
	.rating-wrapper {margin: -10px -5px;}
	.rating {width: calc(min(33% - 10px, 111px)); margin: 10px 5px;}
	.rating__photo {width: 111px; height: 111px; max-width: 100%;}
	.schedule {margin: -5px;}
	.schedule__item {width: 100%; margin: 5px;}
	.create_tr {
    position: initial;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
	}
	.teachers {margin: -5px 0px;}
	.teacher {
    flex-basis: 100%;
    width: 100%;
    margin: 5px 0px;
	}
	.panel-stats {position: initial; margin-top: 10px;}
	.panel-stats div:nth-child(1) {margin-left: 0;}
	.mastery {flex-direction: column;}
	.mastery .page-btn {flex-basis: 50%;}
	.mastery .page-controls:after {width: 50%;}
	.mastery .data-page[data-page="2"] .page-controls:after {transform: translateX(100%);}
	.online__text {margin-right: calc(100% - 213px);}
	.mastery__right {padding-left: 0;}
	.mastery__right .btn {margin-top: 20px; margin-bottom: 30px;}
	.mastery__left {
    order: 2;
    padding-top: 30px;
    border-top: 1px solid var(--text100);
    border-right: none;
    padding-right: 0;
	}
	.mastery label[for="g2"] {margin-top: 20px; margin-left: 0;}
	.mastery .profile__row:nth-child(5) > .profile__row,
	.mastery .profile__row:nth-child(6) > .profile__row {align-items: flex-start !important;}
	.mastery .profile__row:nth-child(5) > .input-wrapper {
    flex: 0 0 calc(50% - 10px);
    margin-top: 20px;
	}
	.mastery .profile__row:nth-child(5) {flex-direction: row;}
	.mastery .profile__row:nth-child(5) > .input-wrapper:nth-child(2) {flex-basis: 211px;}
	.mastery .profile__row:nth-child(5) > .input-wrapper:nth-child(3) {margin-left: 10px; flex-basis: calc(100% - 221px);}
	.mastery .faq-wrapper {margin-top: 60px;}
	.workbook-wrapper {margin-top: 20px;}
	.workbook__buttons {
    margin-right: 0;
    flex-direction: row;
		width: 100%;
	}
	.workbook__buttons .btn {padding: 12px 23px; width: 50%;}
	.workbook__buttons .grey {
    margin-left: 10px;
    margin-top: 0;
	}
	.workbook__left {flex-direction: column;}
	.workbook__folder:last-child {margin-bottom: 0;}
	.filters {
    width: calc(100% - 20px);
    right: unset;
    left: 10px;
    top: 206px;
	}
	.filters .input-wrapper {width: 100%;}
	.workbook__folders {
    margin-top: 140px;
    width: 100%;
	}
	.workbook__folder__title {width: 100%;}
	.workbook__folder__title span:nth-child(2) {width: calc(100% - 60px);}
	.workbook {margin: 15px -5px -5px;}
	.workbook__user {
    width: 100%;
    margin: 5px;
	}
	.workbook__full-wrapper {flex-direction: column; max-height: calc(100vh - 225px); overflow-y: overlay;}
	.workbook__full-user {width: calc(100vw - 60px);}
	.workbook__full .workbook__user__info {margin-top: 20px; margin-bottom: 20px;}
	.workbook__full-actions {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
		flex-direction: row;
    flex-wrap: wrap;
		justify-content: space-between;
	}
	.workbook__full-actions .btn:nth-child(n + 3) {width: calc(50% - 5px);}
	.workbook__full-actions .btn:nth-child(1),
	.workbook__full-actions .btn:nth-child(2),
	.workbook__full-actions .btn:nth-child(7) {
    width: 100%;
	}
	.workbook__full-actions__move, .workbook__full-actions__note, .workbook__full-actions__alert {
    right: 20px;
    top: unset;
    bottom: 153px
	}
	.workbook__full-actions__alert .profile__row {flex-direction: row;}
	.workbook__full-actions__alert .profile__row > * + * {margin-top: 0;}
	.translation__left {margin-bottom: 82px;}
	.translation__right > .btn {top: 364px;}
	.h1-time {
    position: absolute;
    left: 10px;
    top: 376px;
	}
	.dashboard > * {width: 100%; margin-left: 0 !important; margin-bottom: 10px;}
	.dashboard .stats-block:nth-child(7) {
    order: 2;
    position: initial;
	}
	.dashboard__rating {order: 8;}
	.dashboard__profile {height: 127px;}
	.dashboard .stats-block {margin-bottom: 10px;}
	.team_filters > *:nth-child(1) {
    flex-basis: 100%;
    margin-bottom: 10px;
	}
	.team_filters > *:nth-child(2) {margin-left: 0;}
	.club_card {padding: 20px; flex-direction: column; border: 1px solid #E1E6F0; border-radius: 10px;}
	.club_card__left {flex-direction: column;}
	.club_card__img {width: 100%;}
	.club_card__body {margin-left: 0; margin-top: 20px;}
	.club_card__buttons {margin-left: 0; margin-top: 20px; width: 100%; text-align: center;}
	.club_card__buttons .btn {width: 100%;}
	.club_card_info {height: unset;}
	.club_card_info__left {padding-bottom: 40px;}
	.club_card_info h1 {width: unset; margin-top: 272px;}
	.club_card_info h1 + .text400 {width: unset;}
	.club_card_info__right {width: 100%;}
	.club_card_info__bg {background-size: 510px; background-position-x: -49px;}
	.club_card_info__card {left: 23px; top: 92px;}
	.modal__text {width: calc(100vw - 60px); max-height: calc(100vh - 225px);}
}
.content-body > h1 {margin-bottom: 20px;}
.dialog {position: relative;}
.dialog:not(.active):hover {
    background: var(--text100);
}
.dialog__photo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.dialog__photo:after {
    content: attr(data-name);
    font-size: 24px;
    color: #fff;
}
.mes .dialog__photo:after {
  font-size: 16px;
}
.dialog__photo:not(.empty):after {
  content: none;
}
.dialog.active {
  background: var(--text700);
}
.messages textarea.body_s {
    font-family: inherit;
    font-weight: inherit;
    border: 1px solid var(--text100);
    border-radius: 10px;
    padding: 13px 14px;
    width: 100%;
    color: var(--text600);
    outline: none;
    transition: 0.2s;
    height: 46px;
    resize: none;
}
.dialog:after {
    content: attr(data-count);
    position: absolute;
    left: 42px;
    top: 42px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    color: var(--primary);
    background: var(--primary);
    color: #fff;
    border: 2px solid;
}
.dialog:hover:after {
	border-color: var(--text100);
}
.dialog.active:after {
	border-color: var(--text700);
}
.dialog[data-count="0"]:after {
	content: none;
}
.dialog__body_mes video, .dialog__body_mes img {
	max-width: 100%;
	max-height: 570px;
  }
  .input-attach {
	padding-top: 8px;
	padding-right: 10px;
	position: relative;
  }
  .attach-wrapper {
	position: absolute;
	bottom: 36px;
	width: 200px;
	border-radius: 5px;
	padding-bottom: 15px;
	overflow: hidden;
	display: none;
  }
  .input-attach:hover .attach-wrapper, .attach-wrapper:hover {
	display: block;
  }
  .attach {
	padding: 11px 20px;
	cursor: pointer;
	background: var(--bg);
	box-shadow: 0px 2px 2px rgb(40 41 48 / 10%);
	display: block;
  }
  .attach:last-child {
	border-radius: 0 0 5px 5px;
  }
  .attach:hover {
	color: #fff;
	background: var(--primary);
  }
  .command-block {
	flex: 1 1 30%;
	margin-left: 22px;
	border: 1px solid var(--text100);
	border-bottom: none;
	padding: 22px;
	height: 100%;
	max-height: 100%;
	max-width: 300px;
	overflow: overlay;
  }
  .command-block h2 {
	padding-bottom: 10px;
  }
  .command-block .text200 {
	margin-top: 10px;
  }
  .command-block select {margin-top: 16px; padding: 13px 14px;}
  .flex {
	display: flex;
	justify-content: space-between;
  }
  .command-block .flex {cursor: pointer; padding: 2px 0;}
  .command-block .flex :nth-child(1) {min-width: 150px;}
  .command-block .flex:hover {background: var(--text100);}
  @media (max-width: 1439px) and (min-width: 768px) {
	.dialog-box {
		margin-left: -40px;
		margin-right: -40px;
	}
	.messages {
		max-width: calc(100vw - 650px);
	}
  }